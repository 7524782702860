/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import Portfolio1750DT from '~/assets/img/reserve-cask/simplified/1750_desktop_2x.webp';
import Portfolio1750MO from '~/assets/img/reserve-cask/simplified/1750_mobile_2x.webp';
import Portfolio3500DT from '~/assets/img/reserve-cask/simplified/3500_desktop_2x.webp';
import Portfolio3500MO from '~/assets/img/reserve-cask/simplified/3500_mobile_2x.webp';
import Portfolio5250DT from '~/assets/img/reserve-cask/simplified/5250_desktop_2x.webp';
import Portfolio5250MO from '~/assets/img/reserve-cask/simplified/5250_mobile_2x.webp';
import Portfolio7000DT from '~/assets/img/reserve-cask/simplified/7000_desktop_2x.webp';
import Portfolio7000MO from '~/assets/img/reserve-cask/simplified/7000_mobile_2x.webp';
import Portfolio10500DT from '~/assets/img/reserve-cask/simplified/10500_desktop_2x.webp';
import Portfolio10500MO from '~/assets/img/reserve-cask/simplified/10500_mobile_2x.webp';
import Jack from '~/assets/img/support/Jack.png';
import Jonty from '~/assets/img/support/Jonty.png';
import Hero from '~/assets/img/reserve-cask/simplified/simplified_hero.webp';
import HeroMO from '~/assets/img/reserve-cask/simplified/simplified_hero_mo.webp';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { DepositTransferModalKey, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { useUserPreferences } from '~/hooks/account/useUserPreferences';
import { PortfolioTypes } from '~/models/PortfolioType';
import { AnalyticsEventNames } from '~/tracking';
import { formatCurrency } from '~/utils/currencyFormatter';
import { Button, Card } from '~/vinovest-design-system/components';
import dynamic from 'next/dynamic';
import { useWindowSize } from '~/hooks/useWindowSize';
import Image from 'next/image';

const BookACallPopup = dynamic(import('~/components/modules/BookACall/BookACallPopup'));

type HowMuchInvestingCase = 1750 | 3500 | 5250 | 7000 | 8750 | 'book_a_call';

export const ReserveSimplified = () => {
    const router = useRouter();
    const { isMobile } = useWindowSize();
    const { setDepositAmount, setDestinationPortfolio } = useContext(DepositContext);
    const { data: preferences } = useUserPreferences();
    const answerHowMuchInvesting = preferences?.data?.quizQuestions?.howMuchInvesting?.answer;
    const answerNetWorth = preferences?.data?.quizQuestions?.netWorth?.answer;
    const [selectedAnswerAmount, setSelectedAnswerAmount] = useState<HowMuchInvestingCase>(3500);

    const Chart1750 = isMobile ? Portfolio1750MO : Portfolio1750DT;
    const Chart3500 = isMobile ? Portfolio3500MO : Portfolio3500DT;
    const Chart5250 = isMobile ? Portfolio5250MO : Portfolio5250DT;
    const Chart7000 = isMobile ? Portfolio7000MO : Portfolio7000DT;
    const Chart10500 = isMobile ? Portfolio10500MO : Portfolio10500DT;
    const [selectedImg, setSelectedImg] = useState(Chart10500);

    // set the portfolio type for deposit modal
    useEffect(() => {
        setDestinationPortfolio(PortfolioTypes.whiskey);
    });

    useEffect(() => {
        if (answerHowMuchInvesting) {
            switch (answerHowMuchInvesting) {
                case 'lessThan5k':
                    answerNetWorth === 'less_than_100k'
                        ? setSelectedAnswerAmount(1750)
                        : answerNetWorth === 'more_than_5m'
                          ? setSelectedAnswerAmount('book_a_call')
                          : setSelectedAnswerAmount(3500);
                    break;
                case '5k_10k':
                    answerNetWorth === '1m_to_5m' || answerNetWorth === 'more_than_5m'
                        ? setSelectedAnswerAmount('book_a_call')
                        : setSelectedAnswerAmount('book_a_call');
                    break;
                case '10k_50k':
                    setSelectedAnswerAmount('book_a_call');
                    break;
                case '50k_250k':
                    setSelectedAnswerAmount('book_a_call');
                    break;
                case '250k_more':
                    setSelectedAnswerAmount('book_a_call');
                    break;
                default:
                    setSelectedAnswerAmount(3500);
            }
        }

        switch (selectedAnswerAmount) {
            case 1750:
                setSelectedImg(Chart1750);
                break;
            case 3500:
                setSelectedImg(Chart3500);
                break;
            case 5250:
                setSelectedImg(Chart5250);
                break;
            case 7000:
                setSelectedImg(Chart7000);
                break;
            case 8750:
                setSelectedImg(Chart10500);
                break;
            default:
                setSelectedImg(Chart10500);
        }
    }, [answerHowMuchInvesting, answerNetWorth, selectedAnswerAmount]);

    const handleClick = (location: string) => {
        setDepositAmount(selectedAnswerAmount as number);
        PosthogHelper.captureEvent(AnalyticsEventNames.SelectReserve, { location });
        PosthogHelper.captureEvent(AnalyticsEventNames.AddFunds);
        UrlModalHelper.openModal(router, DepositTransferModalKey);
    };

    return (
        <>
            <Card
                shadow={'none'}
                borderRadius={'lg'}
                classnames={
                    'text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] min-h-[556px] md:min-h-[664px] w-full max-w-[1170px] flex items-center justify-center !mt-0 md:!mt-[27px]'
                }
            >
                <div className={'w-[100%] top-0 left-0 z-0'}>
                    <Image src={isMobile ? HeroMO : Hero} alt={'whiskey barrels in storage'} layout={'fill'} />
                </div>
                <p
                    className={
                        'absolute w-[90%] md:max-w-[800px] bottom-[20px] left-[50%] translate-x-[-50%] z-0 text-white text-[12px] md:text-[18px]'
                    }
                >
                    Your whiskey will be safely stored and insured in our professionally managed warehouse
                </p>
                {selectedAnswerAmount !== 'book_a_call' && (
                    <div
                        className={
                            'absolute z-[2] bg-white text-platinum-800 p-[20px] md:p-[45px] rounded-[0.5rem] max-w-[370px] w-[100%] mx-auto'
                        }
                    >
                        <p>We recommend starting with </p>
                        <span
                            className={
                                'text-[44px] md:text-[64px] flex w-[100%] my-[10px] justify-center font-roslindale'
                            }
                        >
                            {formatCurrency(selectedAnswerAmount, false)}
                        </span>
                        <p
                            className={
                                'text-[14px] flex w-[100%] justify-center text-gray-500 max-w-[230px] mb-[20px] mx-auto'
                            }
                        >
                            Don't worry, you can change this amount in a later step
                        </p>
                        <Button
                            variant={'green'}
                            onClick={() => {
                                handleClick('hero');
                            }}
                            classnames={'w-[229px] capitalize'}
                        >
                            Continue
                        </Button>
                    </div>
                )}
                {selectedAnswerAmount === 'book_a_call' && (
                    <div
                        className={
                            'absolute z-[2] bg-white text-platinum-800 p-[20px] md:p-[45px] rounded-[0.5rem] max-w-[370px] w-[100%] mx-auto'
                        }
                    >
                        <p className={'max-w-[260px] text-[16px] md:text-[20px] mx-auto'}>
                            You've unlocked access to our expert advisors. Book a call to receive{' '}
                            <span className={'text-brown-600'}>personalized portfolio recommendations</span>.
                        </p>
                        <div className={'mb-[20px] mt-[40px]'}>
                            <span className={'text-gray-500 uppercase text-[12px]'}>Expert Whiskey Advisors</span>
                        </div>
                        <div className={'flex gap-[40px] mb-[40px] items-center justify-center'}>
                            <div>
                                <div className={'relative w-[47px] h-[47px] mx-auto'}>
                                    <Image
                                        src={Jack}
                                        width={47}
                                        height={47}
                                        objectFit={'contain'}
                                        alt={'Jack'}
                                        className={'w-[47px] h-[47px]'}
                                    />
                                </div>
                                <div className={'flex flex-col'}>
                                    <span className={'font-roslindale text-[18px]'}>Jack Toghli</span>
                                </div>
                            </div>
                            <div>
                                <div className={'relative w-[47px] h-[47px] mx-auto'}>
                                    <Image
                                        src={Jonty}
                                        width={47}
                                        height={47}
                                        objectFit={'contain'}
                                        alt={'Jonty'}
                                        className={'w-[47px] h-[47px]'}
                                    />
                                </div>
                                <div className={'flex flex-col'}>
                                    <span className={'font-roslindale text-[18px]'}>Jonty Barrie</span>
                                </div>
                            </div>
                        </div>
                        <BookACallPopup
                            url={'whiskey'}
                            tracking={{
                                component: 'ReserveWhiskeyBookACall',
                                location: 'hero'
                            }}
                        />
                    </div>
                )}
            </Card>
            <Card
                shadow={'none'}
                borderRadius={'lg'}
                classnames={
                    'bg-gray-200 text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] md:min-h-[664px] w-full max-w-[1170px] '
                }
            >
                <p className={'max-w-[640px] mx-auto md:mt-[30px] text-[18px] md:text-[20px]'}>
                    Here's what a sample portfolio could look like based on an estimated deposit of{' '}
                    <span className={'text-brown-600'}>
                        {selectedAnswerAmount !== 'book_a_call'
                            ? formatCurrency(selectedAnswerAmount, false)
                            : '$8,750'}
                    </span>{' '}
                    and an investing timeline of <span className={'text-brown-600'}>4-8 years.</span>
                </p>
                <div
                    className={`relative w-[${selectedImg.width / 2}] h-[${
                        selectedImg.height / 2
                    }] md:w-[950px] md:h-[450px] my-[40px] mx-auto flex justify-center`}
                >
                    <Image
                        src={selectedImg.src}
                        width={isMobile ? selectedImg.width / 2 : 950}
                        height={isMobile ? selectedImg.height / 2 : 450}
                        alt={'Chart'}
                        className={`relative w-[${selectedImg.width / 2}] h-[${
                            selectedImg.height / 2
                        }] md:w-[950px] md:h-[450px]`}
                    />
                </div>
                {selectedAnswerAmount !== 'book_a_call' && (
                    <Button
                        variant={'green'}
                        onClick={() => {
                            handleClick('sample_package');
                        }}
                        classnames={'w-[229px] capitalize'}
                    >
                        Continue
                    </Button>
                )}
                {selectedAnswerAmount === 'book_a_call' && (
                    <BookACallPopup
                        url={'whiskey'}
                        tracking={{
                            component: 'ReserveWhiskeyBookACall',
                            location: 'sample_package'
                        }}
                    />
                )}
            </Card>
        </>
    );
};
