import { useWindowSize } from '~/hooks/useWindowSize';

export const ReserveTitle = () => {
    const { isDesktop } = useWindowSize();
    return (
        <>
            {isDesktop && (
                <h1 className={'text-platinum-800 font-roslindale xs:w-[300px] sm:w-full text-[36px] md:text-[64px]'}>
                    Invest in Whiskey
                </h1>
            )}
        </>
    );
};
