import AmericanPNG from '~/assets/img/reserve-cask/american-card.webp';
import UltraRareScotchPNG from '~/assets/img/reserve-cask/rare-card.webp';
import { WhiskeyTypes } from '~/constants/WhiskeyTypes';

import { WhiskeyCard } from './WhiskeyCard';
import { useReserveList } from '../hooks/useReserveList';
import { whiskeyRoutes } from '~/routes/whiskey';

export const whiskeyCardList: { key: Exclude<WhiskeyTypes, WhiskeyTypes.NewAgeScotch>; img: any }[] = [
    {
        key: WhiskeyTypes.American,
        img: { src: AmericanPNG.src, height: 259, width: 302 }
    },
    {
        key: WhiskeyTypes.UltraRareScotch,
        img: { src: UltraRareScotchPNG.src, height: 259, width: 302 }
    }
];

type ReserveListExcludingNewAge = { [K in Exclude<WhiskeyTypes, WhiskeyTypes.NewAgeScotch>]: ReserveListProps };

interface Point {
    description: string;
    key: string;
}

export interface ReserveListProps {
    title: string;
    name: string;
    price: string;
    buttonLabel: string;
    volume: string;
    url: string;
    unitTitle: string;
    callout?: string;
    pricePer?: string;
    pointsList: Point[];
}
export const reserveList: ReserveListExcludingNewAge = {
    [WhiskeyTypes.Lots]: {
        title: 'Standard',
        name: 'American Whiskey',
        price: '$650',
        volume: '17 Gallons',
        buttonLabel: 'View Product',
        url: `${whiskeyRoutes.invest.href}/whiskey-lots`,
        unitTitle: 'Starting at',
        pricePer: '$37.49/Gallon',
        pointsList: [
            {
                description: `Robust storage and insurance`,
                key: 'Lot 1'
            },
            {
                description: `Diversified mash bills`,
                key: 'Lot 2'
            }
        ]
    },
    [WhiskeyTypes.American]: {
        title: 'Premier',
        name: 'American Whiskey Casks',
        volume: '53 Gallons',
        price: '$1,750',
        buttonLabel: 'View Product',
        url: `${whiskeyRoutes.invest.href}/american-whiskey-casks`,
        unitTitle: 'Starting at',
        callout: 'Most Popular',
        pricePer: '$33.02/Gallon',
        pointsList: [
            {
                description: `Robust storage and insurance`,
                key: 'Cask 1'
            },
            {
                description: `Premium mash bills`,
                key: 'Cask 2'
            },
            {
                description: `Invite to an annual cask visit trip`,
                key: 'Cask 3'
            },
            {
                description: `Future cask-only benefits`,
                key: 'Cask 4'
            }
        ]
    },
    [WhiskeyTypes.UltraRareScotch]: {
        title: 'Ultra-Rare',
        name: 'Scotch Casks',
        volume: 'Various Sizes',
        price: '$15,000',
        buttonLabel: 'View Product',
        url: `${whiskeyRoutes.invest.href}/ultra-rare-scotch-casks`,
        unitTitle: 'Starting at',
        pointsList: [
            {
                description: `Robust storage and insurance`,
                key: 'Scotch 1'
            },
            {
                description: `Premium mash bills`,
                key: 'Scotch 2'
            },
            {
                description: `Access to the rarest blue-chip scotch whiskies`,
                key: 'Scotch 3'
            },
            {
                description: `Invite to an annual cask visit trip`,
                key: 'Scotch 4'
            },
            {
                description: `Future cask-only benefits`,
                key: 'Scotch 5'
            },
            {
                description: `Dedicated portfolio manager ($50k minimum)`,
                key: 'Scotch 6'
            }
        ]
    }
};

export interface ReserveListCardProps {
    showLoggedIn: boolean;
}

export const ReserveList = ({ showLoggedIn }: ReserveListCardProps) => {
    const { handleReserveClick } = useReserveList();

    return (
        <>
            <div className={'reserve-list-container max-w-[1170px] pt-[0px] md:pt-[70px]'}>
                <div className={'flex justify-between flex-col md:flex-row gap-[40px] md:gap-[30px]'}>
                    {whiskeyCardList.map((card, i) => (
                        <WhiskeyCard
                            key={`${card.key}`}
                            type={`${card.key}`}
                            blackVariant={i % 2 === 1}
                            imgSrc={card.img}
                            handleReserveClick={handleReserveClick(card.key)}
                            showLoggedIn={showLoggedIn}
                            {...reserveList[card.key]}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
