import { useCallback, useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { queries } from '~/constants/queryKeys';
import { WhiskeyTypes } from '~/constants/WhiskeyTypes';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { ConnectSourceModalKey, DepositTransferModalKey, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { useCreateTradingPortfolioMutation } from '~/hooks/mutations/trading-portfolio/useCreateTradingPortfolioMutation';
import { useSavedPaymentMethod } from '~/hooks/payments/useSavedPaymentMethod';
import { useTradingPortfolioQuery } from '~/hooks/queries/portfolios/trading/useTradingPortfolioQuery';
import { useCreateWhiskeyPortfolioMutation } from '~/hooks/queries/portfolios/whiskey/useCreateWhiskeyPortfolioMutation';
import { useWhiskeyPortfolioQuery } from '~/hooks/queries/portfolios/whiskey/useWhiskeyPortfolioQuery';
import { useGetUserPortfoliosInfo } from '~/hooks/queries/useGetUserPortfoliosInfo';
import { PortfolioTypes } from '~/models/PortfolioType';
import { whiskeyRoutes } from '~/routes/whiskey';

export const useReserveList = () => {
    const router = useRouter();
    const { whiskey } = useGetUserPortfoliosInfo();
    const { setDestinationPortfolio } = useContext(DepositContext);
    const { data: paymentMethods } = useSavedPaymentMethod(whiskey?.id);
    const queryClient = useQueryClient();
    // Create a whiskey and trading portfolio if they don't already have one
    const { data: whiskeyPortfolio } = useWhiskeyPortfolioQuery();
    const { data: tradingPortfolio } = useTradingPortfolioQuery();
    const { mutateAsync: createWhiskeyPortfolio } = useCreateWhiskeyPortfolioMutation();
    const { mutateAsync: createTradingPortfolio } = useCreateTradingPortfolioMutation();

    useEffect(() => {
        if (!whiskeyPortfolio?.id) {
            createWhiskeyPortfolio();
            setDestinationPortfolio(PortfolioTypes.whiskey);
        }
        if (!tradingPortfolio?.id) createTradingPortfolio();
    }, [whiskeyPortfolio, tradingPortfolio]);

    useEffect(() => {
        const openDepositAfterPayment = () => {
            queryClient.invalidateQueries(queries.getAllManagedPortfolios);
            queryClient.invalidateQueries(queries.getSavedPaymentMethods);
            queryClient.invalidateQueries(queries.getWhiskeyPortfolio);
            if (UrlModalHelper.isModalOpen(ConnectSourceModalKey))
                UrlModalHelper.closeModal(router, ConnectSourceModalKey);
            if (!UrlModalHelper.isModalOpen(DepositTransferModalKey))
                UrlModalHelper.openModal(router, DepositTransferModalKey);
        };

        window.addEventListener(AnalyticsEventNames.NewPaymentMethodAdded, openDepositAfterPayment);
        window.addEventListener(AnalyticsEventNames.PaymentMethodLinked, openDepositAfterPayment);
        return () => {
            window.removeEventListener(AnalyticsEventNames.NewPaymentMethodAdded, openDepositAfterPayment);
            window.removeEventListener(AnalyticsEventNames.PaymentMethodLinked, openDepositAfterPayment);
        };
    }, []);

    const handleReserveClick = useCallback(
        (cardKey: WhiskeyTypes) => {
            return () => {
                if (cardKey === WhiskeyTypes.UltraRareScotch) {
                    PosthogHelper.captureEvent(AnalyticsEventNames.SelectUltraRare);
                } else if (cardKey === WhiskeyTypes.American) {
                    PosthogHelper.captureEvent(AnalyticsEventNames.SelectAmericanWhiskey);
                    void router.push(`${whiskeyRoutes.invest.href}/american-whiskey-casks`);
                } else {
                    PosthogHelper.captureEvent(AnalyticsEventNames.SelectWhiskeyLots);
                    void router.push(`${whiskeyRoutes.invest.href}/ultra-rare-scotch-casks`);
                }
            };
        },
        [paymentMethods?.paymentMethods, router.query]
    );

    return {
        handleReserveClick
    };
};
