import { useRouter } from 'next/router';

import { useAuthentication } from '~/hooks/useAuthentication';
import { whiskeyRoutes } from '~/routes/whiskey';

import { ReserveCaskPage } from './ReserveCaskPage';
import { useEnsureManagedPortfolio } from '../../Onboarding/hooks/useEnsureManagedPortfolio';

export const ReserveWhiskeyPage = () => {
    const router = useRouter();
    const showLoggedIn = router.pathname.includes(whiskeyRoutes.invest.href);
    if (showLoggedIn) {
        useAuthentication(true);
        useEnsureManagedPortfolio();
    }
    return <ReserveCaskPage showLoggedIn={showLoggedIn} />;
};
