import { useWhiskeyPortfolioQuery } from '~/hooks/queries/portfolios/whiskey/useWhiskeyPortfolioQuery';
import { useSendUtmParams } from '~/hooks/tracking/useSendUtmParams';
import { Card } from '~/vinovest-design-system/components';

import { HowWhiskeyvestWorksContainer } from './components/HowWhiskeyvestWorks/HowWhiskeyvestWorksContainer';
import { ReserveList } from './components/ReserveList';
import { ReserveSimplified } from './components/ReserveSimplified';
import { ReserveTitle } from './components/ReserveTitle';
import { TryWineCard } from './components/TryWineCard';
import { ValuePropContainer } from './components/ValuePropList/ValuePropContainer';
import { WhiskeyEducationSupportContainer } from './components/WhiskeyEducationSupport';
import { GreenButton } from '../../TrackRecord/GreenButton';
import { WhatToExpectContainer } from './components/WhatToExpect/WhatToExpectContainer';
import dynamic from 'next/dynamic';
import { useBookACallStore } from '~/components/modules/BookACall/BookACallStore';
import { observer } from 'mobx-react-lite';

const BookACallCta = dynamic(import('./components/BookACallCta'));

export interface ReservePageProps {
    showLoggedIn: boolean;
}

export const ReserveCaskPage = observer(({ showLoggedIn }: ReservePageProps) => {
    useSendUtmParams();
    let hasFunds = false;
    const bookStore = useBookACallStore();

    if (showLoggedIn) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { data: whiskeyPortfolio } = useWhiskeyPortfolioQuery();

        if (whiskeyPortfolio?.cash) {
            hasFunds = whiskeyPortfolio.cash.amount + whiskeyPortfolio.positionTotal.amount > 0;
        }
    }
    const showList = (hasFunds && showLoggedIn) || !showLoggedIn || bookStore.hasBookedCall;

    return (
        <div className={'head-container head-container-color pb-10 md:pb-[97px] mb-0 relative pt-[20px] md:pt-[50px]'}>
            <ReserveTitle />
            <div id={'Buy'} />
            {!showList && <ReserveSimplified />}
            {showList && <ReserveList showLoggedIn={showLoggedIn} />}

            <div id={'WhatToExpect'} className={'h-[80px] mt-[-80px]'} />
            <Card
                shadow={'none'}
                borderRadius={'lg'}
                classnames={
                    'bg-gray-200 text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] w-full max-w-[1170px] '
                }
            >
                <WhatToExpectContainer />

                {showLoggedIn && <BookACallCta title={'Have Questions?'} location={'whiskey_returns'} />}
                {!showLoggedIn && (
                    <div className={'mt-8'}>
                        <GreenButton title={'Get Started'} location={'whiskey_returns'} />
                    </div>
                )}
                <div id={'ValueProp'} />
            </Card>
            {showLoggedIn && (
                <Card
                    shadow={'none'}
                    borderRadius={'lg'}
                    classnames={
                        'bg-gray-200 text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] w-full max-w-[1170px] '
                    }
                >
                    <HowWhiskeyvestWorksContainer />
                    {showLoggedIn && <BookACallCta title={'Unlock Exclusive Offers'} location={'whiskey_benefits'} />}

                    <div id={'HowItWorks'} />
                </Card>
            )}
            <Card
                shadow={'none'}
                borderRadius={'lg'}
                classnames={
                    'bg-gray-200 text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] w-full max-w-[1170px] '
                }
            >
                <ValuePropContainer />
                {showLoggedIn && <BookACallCta title={'Unlock Exclusive Offers'} location={'whiskey_benefits'} />}
                {!showLoggedIn && (
                    <div className={'mt-8'}>
                        <GreenButton title={'Get Started'} location={'whiskey_benefits'} />
                    </div>
                )}

                <div id={'WhiskeyEducationSupport'} />
            </Card>
            <Card
                shadow={'none'}
                borderRadius={'lg'}
                classnames={
                    'bg-gray-200 text-center h-full relative pb-[80px] mt-[47px] lg:mt-[67px] w-full max-w-[1170px] '
                }
            >
                <WhiskeyEducationSupportContainer />
                {showLoggedIn && <BookACallCta title={'Still stuck?'} location={'whiskey_education'} />}
                {!showLoggedIn && (
                    <div className={'mt-8'}>
                        <GreenButton title={'Get Started'} location={'whiskey_education'} />
                    </div>
                )}
            </Card>
            {showLoggedIn && <TryWineCard />}
        </div>
    );
});
