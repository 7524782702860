import ReactPlayer from 'react-player';
import Image from 'next/legacy/image';
import styled from 'styled-components';

import VideoDesktopThumbnail from '~/assets/img/whiskey/Video_whiskey-dt-thumbnail.jpg';
import VideoMobileThumbnail from '~/assets/img/whiskey/Video_whiskey-mo-thumbnail.jpg';
import { VideoContainer } from '~/components/views/Managed/Overview/PreDepositPortfolio';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { AnalyticsEventNames } from '~/tracking';
import { Heading, Text } from '~/vinovest-design-system/components';

import { howWhiskeyvestWorksCardConstants } from './howWhiskeyvestWorksCardConstants';
import { HowWhiskeyvestWorksList } from './HowWhiskeyvestWorksList';
import { useWindowSize } from '~/hooks/useWindowSize';

export const HowWhiskeyvestWorksContainer = () => {
    const { isMobile } = useWindowSize();
    const videoID = isMobile ? '883096746' : '883096195';
    const videoThumb = isMobile ? VideoMobileThumbnail : VideoDesktopThumbnail;
    const thumbnailWidth = isMobile ? '281' : '1024';
    const thumbnailHeight = isMobile ? '480' : '576';
    return (
        <HwiwContainer className={'h-full sm:bg-transparent max-w-[1100px] mx-auto'}>
            <div className={'flex justify-center flex-col'}>
                <Heading level={'h2'} classnames={'xs:!text-[32px] lg:!text-[45px] text-center !leading-loose'}>
                    How it Works
                </Heading>
                <Text classnames={'xs:pb-8 sm:pb-0 pt-[20px] text-center'}>
                    Vinovest allows you to buy, sell, and store your unique casks of whiskey in one place.
                </Text>
            </div>
            <VideoContainer
                className={
                    'mx-auto w-[281px] md:w-[1024px] h-[480px] md:h-[576px] rounded-[10px] overflow-hidden mt-8 mb-12'
                }
            >
                <ReactPlayer
                    url={`https://player.vimeo.com/video/${videoID}?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479&texttrack=en-x-autogen`}
                    config={{
                        vimeo: {
                            playerOptions: {
                                id: `${videoID}`,
                                playsinline: true,
                                autoplay: true,
                                muted: false,
                                controls: true,
                                texttrack: 'en-x-autogen'
                            }
                        }
                    }}
                    height={'100%'}
                    width={'100%'}
                    className={'rounded-[10px]'}
                    onPlay={() => PosthogHelper.captureEvent(AnalyticsEventNames.VideoStart, { type: 'whiskey' })}
                    onEnded={() => PosthogHelper.captureEvent(AnalyticsEventNames.VideoEnd, { type: 'whiskey' })}
                    light={<Image src={videoThumb} alt={'Thumbnail'} width={thumbnailWidth} height={thumbnailHeight} />}
                />
            </VideoContainer>
            <HowWhiskeyvestWorksList cards={howWhiskeyvestWorksCardConstants} />
        </HwiwContainer>
    );
};

export const HwiwContainer = styled.div`
    padding: 20px 0;
    position: relative;
`;
